<template>
    <div class="container" style="margin-top: 100px;">

        <section data-bs-version="5.1" class="header5 cid-u7ancmRRnP" id="header05-1x">
	

	
	
	<div class="topbg"></div>
	<div class="align-center container-fluid">
		<div class="row justify-content-center">
			<div class="col-md-12 content-head" style="text-align: center;">
				<h1 class="mbr-section-title mbr-fonts-style mb-4 display-2">
					<strong>Executive Members</strong></h1>
				
				
			</div>
		</div>
		<h2 class="pb-2 border-bottom">Meet the Executives</h2>
        <div class="d-flex justify-content-around flex-wrap">
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Sivamoorthy Bose.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Sivamoorthy Bose</strong></h5>
                <p class="text-muted" style="text-align: center;" >President</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Mani.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Santhana manimaran</strong></h5>
                <p class="text-muted" style="text-align: center;" >Vice President</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Sheela.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Vergin Sheela</strong></h5>
                <p class="text-muted" style="text-align: center;" >Secretary</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Bhuvana.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Bhuvaneshwari Elango</strong></h5>
                <p class="text-muted" style="text-align: center;" >Treasurer</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/JayaPrakash.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />

                <h5 class="mb-2 name" style="text-align: center;"><strong>Jayaprakash Sithusamy</strong></h5>
                <p class="text-muted" style="text-align: center;" >Joint Secretary</p>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Anita Gandavadi.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Anita Gandavadi</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Siva Selvan.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Siva Selvan</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Pravin Loyola.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Pravin Loyola</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Annie.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Annie</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Palani.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Palani</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Don Bosco Samson.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Don Bosco Samson</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Selvaganapathy Muthukrishnan.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Selvaganapathy Muthukrishnan</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Dhanavelan Jegadeesan.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Dhanavelan Jegadeesan</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Chander.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Chander</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/SrinivasanPotti.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Srinivasan Potti</strong></h5>
            </div>
            <div class="team-item-width">
                <img src="assets/images/ExecutiveBoard/Ravisankar Velayutham.jpg" class="rounded-circle mb-3 avatar"
                alt="Avatar" />
                <h5 class="mb-2 name" style="text-align: center;"><strong>Ravisankar Velayutham</strong></h5>
            </div>

        </div>
	</div>
</section>




</div>

  
  
</template>

<script>

export default {
    name: "activity",
    components: {},
    data() {
        return {
        
        };
    },
    methods: {
    }
};

</script>