import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Components from "./views/Components.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Home from "./views/Home.vue";
import Activity from "./views/Activity.vue";
import Event from "./views/Event.vue";
import TamilSchool from "./views/TamilSchool.vue";
import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";
import RSVP from "./views/RSVP.vue";
import RSVPRegistration from "./views/RSVPRegistration.vue";
import MembershipRegistration from "./views/MembershipRegistration.vue";
import Donation from "./views/Donation.vue";
import SchoolRegistration from "./views/SchoolRegistration.vue";
import SchoolRegistrationList from "./views/SchoolRegistrationList.vue";
import RSVPList from "./views/RSVPList.vue";
import RSVPRegistrationList from "./views/RSVPRegistrationList.vue";
import MembershipList from "./views/MembershipList.vue";
import ActivityList from "./views/ActivityList.vue";
import ActivityAdmin from "./views/ActivityAdmin.vue";
import EventEntry from "./views/EventEntry.vue";
import Benefits from "./views/Benefits.vue";
import AboutUs from "./views/AboutUs.vue";
import BoardMembers from "./views/BoardMembers.vue";
import ExecutiveMembers from "./views/ExecutiveMembers.vue";
import Gallery from "./views/Gallery.vue";
import Calendar from "./views/Calendar.vue";
import firebase from './Firebase.js'

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "Home",
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/landing",
      name: "landing",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      meta:{
        requiresAuth: true
      },
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },
    {
      path: "/home",
      name: "home",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/home/:id",
      name: "home",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/activity",
      name: "activity",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Activity,
        footer: AppFooter
      }
    },
    {
      path: "/activity/:id",
      name: "activity",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Activity,
        footer: AppFooter
      }
    },
    {
      path: "/event",
      name: "event",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Event,
        footer: AppFooter
      }
    },
    {
      path: "/tamilSchool",
      name: "tamilSchool",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: TamilSchool,
        footer: AppFooter
      }
    },
    {
      path: "/register",
      name: "register",
      meta:{
        requiresAuth: true
      },
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      }
    },
    {
      path: "/profile",
      name: "profile",
      meta:{
        requiresAuth: true
      },
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter
      }
    },
    {
      path: "/RSVPRegistration",
      name: "RSVPRegistration",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: RSVPRegistration,
        footer: AppFooter
      }
    },
    {
      path: "/RSVPRegistration/:rsvpId",
      name: "RSVPRegistration",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: RSVPRegistration,
        footer: AppFooter
      }
    },
    {
      path: "/MembershipRegistration",
      name: "MembershipRegistration",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: MembershipRegistration,
        footer: AppFooter
      }
    },
    {
      path: "/Donation",
      name: "Donation",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: Donation,
        footer: AppFooter
      }
    },
    {
      path: "/SchoolRegistration",
      name: "SchoolRegistration",
      meta:{
        requiresAuth: false
      },
      components: {
        header: AppHeader,
        default: SchoolRegistration,
        footer: AppFooter
      }
    },
    {
      path: "/SchoolRegistrationList",
      name: "SchoolRegistrationList",
      meta:{
        requiresAuth: true,
        requiresAdmin: true
      },
      components: {
        header: AppHeader,
        default: SchoolRegistrationList,
        footer: AppFooter
      }
    },
    {
      path: "/RSVPRegistrationList",
      name: "RSVPRegistrationList",
      meta:{
        requiresAuth: true,
        requiresAdmin: true
      },
      components: {
        header: AppHeader,
        default: RSVPRegistrationList,
        footer: AppFooter
      }
    },
    {
      path: "/MembershipList",
      name: "MembershipList",
      meta:{
        requiresAuth: true,
        requiresAdmin: true
      },
      components: {
        header: AppHeader,
        default: MembershipList,
        footer: AppFooter
      }
    },
    {
      path: "/ActivityList",
      name: "ActivityList",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: ActivityList,
        footer: AppFooter
      }
    },
    {
      path: "/ActivityAdmin",
      name: "ActivityAdmin",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: ActivityAdmin,
        footer: AppFooter
      }
    },
    {
      path: "/ActivityAdmin/:id",
      name: "ActivityAdmin",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: ActivityAdmin,
        footer: AppFooter
      }
    },
    {
      path: "/RSVP",
      name: "RSVP",
      meta:{
        requiresAuth: true,
        requiresAdmin: true
      },
      components: {
        header: AppHeader,
        default: RSVP,
        footer: AppFooter
      }
    },
    {
      path: "/RSVPList",
      name: "RSVPList",
      meta:{
        requiresAuth: true,
        requiresAdmin: true
      },
      components: {
        header: AppHeader,
        default: RSVPList,
        footer: AppFooter
      }
    },
    {
      path: "/EventEntry",
      name: "EventEntry",
      meta:{
        requiresAuth: true,
        requiresAdmin: true
      },
      components: {
        header: AppHeader,
        default: EventEntry,
        footer: AppFooter
      }
    },
    {
      path: "/Benefits",
      name: "Benefits",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: Benefits,
        footer: AppFooter
      }
    },
    {
      path: "/AboutUs",
      name: "AboutUs",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: AboutUs,
        footer: AppFooter
      }
    },
    {
      path: "/BoardMembers",
      name: "BoardMembers",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: BoardMembers,
        footer: AppFooter
      }
    },
    {
      path: "/ExecutiveMembers",
      name: "ExecutiveMembers",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: ExecutiveMembers,
        footer: AppFooter
      }
    },
    {
      path: "/Gallery",
      name: "Gallery",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: Gallery,
        footer: AppFooter
      }
    },
    {
      path: "/Calendar",
      name: "Calendar",
      meta:{
        requiresAuth: false,
        requiresAdmin: false
      },
      components: {
        header: AppHeader,
        default: Calendar,
        footer: AppFooter
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  let isAdmin = false;
  let scanAdmin = false;

  if(currentUser){
      await firebase.firestore().collection('users').doc(currentUser.uid).get().then((doc) => {
        const userData = doc.data();
        if(userData){
          isAdmin = userData.admin;
        }
        

        try{
          
          scanAdmin = userData.scanadmin;
          // console.log('Scan Admin 1',scanAdmin);
        }
        catch(e){

        }

        // Store the admin role information in a global state management (e.g., Vuex) or local component state
        // Use this information to determine access to specific pages or components
      }).catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }

  // console.log('Scan ---', to);
  // console.log('Scan 1---', requiresAuth);
  // console.log('Scan 2---', requiresAdmin);

  if (requiresAuth && !currentUser) {
    next('/Home');
  } else if (!requiresAuth && currentUser) {
    next('/RSVPRegistrationList');

  } else {
    if(requiresAdmin && (isAdmin || scanAdmin)) {

      console.log(to);
      if(scanAdmin){
        console.log('fdf ', to.name);
        if(to.name == 'EventEntry'){
          console.log(to.name);
          next();
        }
      }
      else{
        next();
      }
      
    }
    else if(!requiresAdmin){
      next();
    }
    
  }
});

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    await firebase.firestore().collection('users').doc(user.uid).get().then((doc) => {
      const userData = doc.data();
      let isAdmin = userData.admin;
      let scanAdmin = userData.scanadmin;

      if(isAdmin){
        router.push('/RSVPRegistrationList');
      }
      else if(scanAdmin){
        console.log('Scan Admin');
        router.push('/EventEntry');
      }
      else{
        router.push('/Home');
      }
      // Store the admin role information in a global state management (e.g., Vuex) or local component state
      // Use this information to determine access to specific pages or components
    }).catch((error) => {
      console.error('Error fetching user data:', error);
      router.push('/Home');
    });
    
  } 
  // else {
  //   router.push('/RSVPRegistration');
  // }
});

export default router
